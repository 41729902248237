import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTimepickerModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PopoverModule  } from 'ngx-bootstrap/popover';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './components/app.component';
import { APP_DECLARATIONS } from './app.declarations';
import { APP_PROVIDERS } from "./app.providers";
import { AppRoutes } from './app.routes';
import { ToastrModule  } from "ngx-toastr";
import { CookieModule } from "ngx-cookie";
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: APP_DECLARATIONS,
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    RouterModule.forRoot(AppRoutes),
    BrowserAnimationsModule,
    NgbModule,
    NgbTimepickerModule,
    ModalModule.forRoot(),
    FontAwesomeModule,
    AlertModule.forRoot(),
    ToastrModule.forRoot({preventDuplicates: true}),
    PopoverModule.forRoot(),
    CookieModule,
    NgbDropdownModule
  ],
  providers: [APP_PROVIDERS],
  bootstrap: [AppComponent]
})
export class AppModule { }
