<loading-text-display [isLoading]="isPageLoading">
    <div class="container-fluid" style="margin:0; padding:0; margin-top: 10px; min-width: 320px; overflow-x: hidden; min-height: 301px;">
        <div class="row">
            <div class="col-sm">
                <picture>
                    <source media="(min-width: 640px)" [srcset]="'./assets/' + appBrand + '-web.png'">
                    <img [src]="'./assets/' + appBrand + '-mobile.png'" [alt]="appBrand">
                </picture>
            </div>
        </div>
        <div class="row">
            <div class="col-sm">
                <wizard #fallOptOutContactWizard [showDefaultControls]="false" (currentStepIndexChange)="onCurrentStepChange($event)">
                    <wizard-step [nextAction]="verifyPhoneNumber()">
                        <div class="fall-management-text-center">
                            <h5 class="fall-management-text-margin" style="margin-top: 40px;">
                                {{_catService.getString("CloudTray_FallManagement_FallOptOutContact_EnterPhoneNumberMessage")}}
                            </h5>
                            <h5 class="fall-management-text-margin" style="margin-top: 40px;">
                                {{_catService.getString("CloudTray_FallManagement_FallOptOutContact_VerificationCodeMessage")}}
                            </h5>
                            <div style="margin:20px 0 20px 0; text-align: center;">
                                <input id="phoneNumber" type="tel" placeholder="{{_catService.getString('CloudTray_FallManagement_FallOptOutContact_PhonePlaceHolder')}}" [(ngModel)]="phoneNumber"
                                    style="min-width: 280px;width: auto;margin: auto;padding: auto;" (ngModelChange)="transformPhoneNumber();"
                                    pattern="^[+]?[\d]+$" class="form-control" />
                                <div style="height:20px;">
                                    <label *ngIf="invalidPhoneNumber" style="color:red; font-size:12px; ">{{_catService.getString("CloudTray_FallManagement_FallOptOutContact_InvalidPhoneNumberError")}}</label>
                                </div>
                            </div>
                        </div>
                    </wizard-step>
                    <wizard-step [nextAction]="checkVerificationCode()">
                        <div class="fall-management-text-center">
                            <h5 class="fall-management-text-margin" style="margin-top: 40px;">
                                {{_catService.getString("CloudTray_FallManagement_FallOptOutContact_TextMessageSent")}}
                            </h5>
                            <h5 class="fall-management-text-margin" style="margin-top: 40px;">
                                {{_catService.getString("CloudTray_FallManagement_FallOptOutContact_EnterCodeText")}}
                            </h5>
                            <div style="margin:20px 0 20px 0; text-align: center;">
                                <input id="verificationCode" type="text" placeholder="{{_catService.getString('CloudTray_FallManagement_FallOptOutContact_VerificationCodePlaceHolder')}}" [(ngModel)]="verificationCode"
                                    style="min-width: 280px;width: auto;margin: auto;padding: auto;" class="form-control" (ngModelChange)="changeToUpperCase();" />
                                    <div style="height:20px;">
                                        <label *ngIf="invalidVerificationCode" style="color:red; font-size:12px; ">{{_catService.getString("CloudTray_FallManagement_FallOptOutContact_InvalidVerificationCodeError")}}</label>
                                    </div>
                            </div>
                        </div>
                    </wizard-step>
                    <wizard-step>
                        <div class="fall-management-text-center">
                            <h5 class="fall-management-text-margin" style="margin-top: 40px;">
                                {{_catService.getString("CloudTray_FallManagement_FallOptOutContact_RemoveScreenText")}}
                            </h5>
                            <div style="margin:10px 0; text-align: center;" *ngFor="let item of contactMappingData">
                                <span class="form-control" style="display:inline-block; width: 65%; text-align: left;">{{item.patientName}}</span>
                                <span class="form-control" [ngClass]="getRemoveButtonClass(item)" style="display:inline-block; width:30%;" (click)="removeContact(item);">
                                    {{item.isBusy ? '' : _catService.getString("CloudTray_FallManagement_FallOptOutContact_RemoveButton")}}
                                    <i *ngIf="item.isBusy" class="fa fa-spinner fa-pulse fa-fw"></i>
                                </span>
                            </div>
                            <div style="margin:10px 0; text-align: center;" *ngIf="contactMappingData.length === 0">
                                <span class="form-control" style="display:inline-block; width: 90%; text-align: left; color: #ced4da">{{_catService.getString("CloudTray_FallManagement_FallOptOutContact_NoUsers")}}</span>                               
                            </div>
                        </div>
                    </wizard-step>

                </wizard>

                <button *ngIf="currentStep < 3" [ngClass]="getWizardButtonClass()"
                    [disabled]="(currentStep === 1 && !isProperNumber()) || (currentStep === 2 && !verificationCode) || awaitingResponse" class="form-control" style="min-width:200px;width: auto;margin: auto;"
                    (click)="goToNextStep()">
                    {{_catService.getString("CloudTray_FallManagement_FallOptOutContact_SubmitText")}}
                </button>
            </div>
        </div>
    </div>
</loading-text-display>