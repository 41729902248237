import { LocationAccuracy, FallAlertConfirmationType } from './fall-alert-constants';

// TODO: Find a way to share this with CFs.
// This is used in CFs so if you make changes here then make sure that CFs repo is updated as well.
export interface GetLocaleAndBrandInfoResponse {
	regionCode: string;
	languageCode?: string;
	appBrand?: string;
}

export interface GetLocaleAndBrandInfoInput {
    id: string;
    confirmationType: FallAlertConfirmationType;
}

export interface ValidateFallAlertPhoneNumberResponse {
	latitude?: number;
	longitude?: number;
	accuracy: LocationAccuracy;
	name?: string;
}

export interface ValidateContactPhoneNumberResponse {
	patientName?: string;
}

export interface GetFallAlertContactMappingResponse {
	contactMaps: ContactMap[];
}

export interface ContactMap {
    uid: string;
    patientName: string;
    contactId: string;
}

export interface ContactMapHosting extends ContactMap {
    isBusy: boolean;
}

interface FirebaseConfig {
    apiKey: string,
    authDomain: string,
    projectId: string,
}
export interface EnvironmentConfig {
    firebase: FirebaseConfig,
    authorizationServiceV2Url: string,
}
