<div *ngIf="currentStepIndex !== null && !isFinished()">
	<div>
		<loading-display [isLoading]="displayLoadingIndicator && !isIdle">
			<ng-content></ng-content>
		</loading-display>
	</div>
	<div *ngIf="showDefaultControls">
		<div class="pull-left">
			<button class="btn btn-success"
					[disabled]="!isIdle"
					(click)="startOver()">
				<i class="fa fa-repeat"></i>
				Start Over
			</button>
		</div>
		<div class="pull-right">
			<button
					[ngClass]="['btn', primaryButtonClass]"
					*ngIf="!isOnFirstStep()"
					[disabled]="!canMoveToPrevious()"
					(click)="goToPreviousStep()">
				<i class="fa fa-chevron-left"></i>
				{{previousText}}
			</button>
			<span *ngIf="displayCounter" class="wizard-counter">{{currentStepIndex}} / {{getTotalSteps()}}</span>
			<button
					[ngClass]=" ['btn', isOnLastStep() ? finishButtonClass : primaryButtonClass]"
					[disabled]="!canMove()"
					(click)="goToNextStep()">
				{{isOnLastStep() ? finishText : nextText}}
				<i class="fa fa-chevron-right"></i>
			</button>
		</div>
	</div>
</div>