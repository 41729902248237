import {OnInit, Component} from '@angular/core';

import {AlertService} from "./alert.service";

const KEY_ESC = 27;

@Component({
    selector: 'modal-alert',
    templateUrl: './alert.component.html',
    styleUrls: ["alert.component.scss"]
})
export class AlertComponent implements OnInit {

    private _defaults = {
        title: '',
        message: '',
        okText: 'OK'
    };
    title:string | undefined;
    message:string | undefined;
    okText:string | undefined;
    cancelText:string | undefined;

    private _alertElement:any;
    private _okButton: any;

    public showDialog = false;

    constructor(alertService: AlertService) {
        alertService.activate = this.activate.bind(this);
    }

    _setLabels(message = this._defaults.message, title = this._defaults.title, okText = this._defaults.okText) {
        this.title = title;
        this.message = message;
        this.okText = okText;
    }

    activate(message = this._defaults.message, title = this._defaults.title, okText = this._defaults.okText) {
        this._setLabels(message, title, okText);
        this._alertElement = document.getElementById('alertModal');
        this._okButton = document.getElementById('alertDialogOkButton');

        let promise = new Promise<boolean>(resolve => {
            this._show(resolve);
        });
        return promise;
    }

    private _show(resolve:(arg0: boolean) => any) {
        document.onkeyup = null;

        let negativeOnClick = (e:any) => resolve(false);
        let positiveOnClick = (e:any) => resolve(true);

        if (!this._alertElement || !this._okButton) return;

        this._okButton.onclick = ((e:any) => {
            e.preventDefault();
            if (!positiveOnClick(e)) this._hideDialog()
        });

        // this._alertElement.onclick = () => {
        //     this._hideDialog();
        //     return negativeOnClick(null);
        // };

        document.onkeyup = (e:any) => {
            if (e.which == KEY_ESC) {
                this._hideDialog();
                return negativeOnClick(null);
            }
        };

        this.showDialog = true;
    }

    public _hideDialog() {
        document.onkeyup = null;
        this.showDialog = false;
    }

    ngOnInit():any {
        this._alertElement = document.getElementById('alertModal');
        this._okButton = document.getElementById('alertDialogOkButton');
    }
}