// Observable Version
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseDataService } from './data.base.service';
import { environment } from '../../../environments/environment';
import { GetLocaleAndBrandInfoResponse, ValidateFallAlertPhoneNumberResponse, ValidateContactPhoneNumberResponse, GetLocaleAndBrandInfoInput, ContactMap, GetFallAlertContactMappingResponse, EnvironmentConfig } from '../../models/fall-alert/fall-alert-interfaces';
import { FallAlertConfirmationType } from '../../models/fall-alert/fall-alert-constants';
import { ConfigurationDataService } from './data.configuration.service';

@Injectable()
export class FallAlertDataService extends BaseDataService {
    constructor(private _http: HttpClient,
        private _apiConfig: ConfigurationDataService) {
        super();
    }

    // TODO: Validate Contact optout/fallAlert/contactRegister should probably be merged into a single CF.
    validateContactPhoneNumber(contactConfirmationId: string, phoneNumber: string): Observable<ValidateContactPhoneNumberResponse>  {
        const data = {
            contactConfirmationId: contactConfirmationId,
            phoneNumber: phoneNumber
        };
        return this._http.post<ValidateContactPhoneNumberResponse>(`${environment.authorizationServiceUrl}/validateContactPhoneNumber`, data);
    }

    validateFallAlertPhoneNumber(alertId: string, phoneNumber: string): Observable<ValidateFallAlertPhoneNumberResponse>  {
        const data = {
            alertId: alertId,
            phoneNumber: phoneNumber
        };
        return this._http.post<ValidateFallAlertPhoneNumberResponse>(`${environment.authorizationServiceUrl}/validateFallAlertPhoneNumber`, data);
    }

    validateContactOptOutPhoneNumber(phoneNumber: string): Observable<void>  {
        const data = {
            phoneNumber: phoneNumber
        };
        
        return this._http.post<any>(`${environment.authorizationServiceUrl}/validateContactOptOutPhoneNumber`, data);
    }

    getLocaleAndBrandInfo(id: string, confirmationType: FallAlertConfirmationType): Observable<GetLocaleAndBrandInfoResponse> {
        const data: GetLocaleAndBrandInfoInput = {
            id: id,
            confirmationType: confirmationType
        };

        return this._http.post<GetLocaleAndBrandInfoResponse>(`${environment.authorizationServiceUrl}/getLocaleAndBrandInfo`, data);
    }

    setAlertAcknowledged(alertId: string, phoneNumber: string): Observable<any> {
        const data = {
            alertId: alertId,
            phoneNumber: phoneNumber
        };

        return this._http.post(`${environment.authorizationServiceUrl}/setAlertAcknowledged`, data);
    }

    confirmContact(confirmId: string, phoneNumber: string): Observable<any> {
        const data = {
            id : confirmId,
            phoneNumber: phoneNumber
        };
        return this._http.post(`${environment.authorizationServiceUrl}/confirmContact`, data);
    }

    getFallAlertContactMapping(optOutValidationToken: string, phoneNumber: string): Observable<GetFallAlertContactMappingResponse> {
        const data = {
            phoneNumber: phoneNumber,
            optOutValidationToken: optOutValidationToken
        };
        return this._http.post<GetFallAlertContactMappingResponse>(`${environment.authorizationServiceUrl}/getFallAlertContactMapping`, data); 
    }

    deleteFallAlertContact(uid: string, contactId: string): Observable<any> {
        const data = {
            uid : uid,
            contactId : contactId,
        };
        return this._http.post(`${environment.authorizationServiceUrl}/deleteFallAlertContact`, data)
    }

    getUserIpInfo() : Observable<any> {
        return this._http.get(`${environment.cloudCoreApiUrl}/api/v1/ipInfo`)
    }
}
