import { Component } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
    template: ""
})
export class FallRedirectComponent  { 

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router) { 
        this._activatedRoute.queryParams.subscribe(params => {
            let fallAlertParam = params["f"] || null;
            let fallConfirmParam = params["c"] || null;
            let fallOptOutParam = params["u"] || null;
            if (fallAlertParam) {
                //window.location.replace(`/fall-alert?alertId=${fallAlertParam}`);
                this._router.navigate(["/fall-alert"], {queryParams: {alertId: fallAlertParam}});
            } else if (fallConfirmParam) {
                window.location.replace(`/fall-confirm-contact?id=${fallConfirmParam}`);
            } else if (fallOptOutParam) {
                window.location.replace(`/fall-opt-out-contact`);
            }
        });

    }
}
