import { Injectable} from '@angular/core';
import * as _ from "lodash";

@Injectable()
export class FallHelper {
    getUserLanguages(): string[] {
        const langs = (_.clone(<string[]>navigator.languages) || [navigator.language]);
        langs.push('en');

        for (let i = 0; i < langs.length; i++) {
            langs[i] = langs[i].toLowerCase().replace('_', '-');

            // If language is xy-ab, then insert xy after the array index containing xy-ab
            if (langs[i].includes('-')) {
                const temp = langs[i].split('-');
                if (temp[0] !== '') {
                    langs.splice(i + 1, 0, temp[0]);
                    i++;
                }
            }
        }
        return langs;
    }
}
