import { Component, Input, Output, EventEmitter } from '@angular/core';

import * as _ from 'lodash';

import { WizardStep, TruePromise } from './wizard-step';

@Component({
	selector: 'wizard',
	templateUrl: './wizard.component.html',
	styleUrls: [ './wizard.component.css' ]
})
export class WizardComponent {
	private _currentStepIndex: number = null;
	
	private _steps: WizardStep[] = [];

	private _isIdle: boolean = true;
	@Input()
	get isIdle(): boolean {
		return this._isIdle;
	}
	set isIdle(value: boolean) {
		this.idleStateChange.emit(value);
		this._isIdle = value;
	}

	@Input()
	nextText: string = "Next";

	@Input()
	previousText: string = "Previous";

	@Input()
	finishText: string = "Finish";

	@Input()
	displayCounter: boolean = true;

	@Input()
	displayLoadingIndicator: boolean = true;

	@Input()
	disableMovement: boolean = false;

	@Input()
	primaryButtonClass: string = "btn-primary"

	@Input()
	finishButtonClass: string = "btn-success";

	@Input()
	startOverAction: () => Promise<boolean> = () => TruePromise;

	@Input() showDefaultControls: boolean = true;

	@Output()
	idleStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	canMove(): boolean {
	    return this.isIdle && !this.disableMovement;
	}

	canMoveToPrevious(): boolean {
		return this.canMove() && !this._steps[this.currentStepIndex].disablePrevious;
	}

	get currentStepIndex(): number {
		return this._currentStepIndex;
	}
	set currentStepIndex(value: number) {
		this.isIdle = false;
		let exitAction =
			this.currentStepIndex !== null && this.currentStepIndex <= (this.getTotalSteps() - 1)
				? this._steps[this.currentStepIndex].exitAction()
				: TruePromise;
		exitAction
			.then(result =>
			{
				if (!result) {
					this.isIdle = true;
					return;
				}

				this._currentStepIndex = value;
				if (value >= this.getTotalSteps()) {
					this.isIdle = true;
					this.finish.emit(value);
				} else {
					this.currentStepIndexChange.emit(this.currentStepIndex);

					this._steps[this.currentStepIndex]
						.enterAction()
						.then(result => this.isIdle = true)
						.catch(error => {
							this.isIdle = true;
							throw error;
						});
				}
			})
			.catch(error => {
				this.isIdle = true;
				throw error;
			});
	}

	@Output()
	currentStepIndexChange: EventEmitter<any> = new EventEmitter<any>();

	goToNextStep() {
		this.isIdle = false;
		this._steps[this.currentStepIndex]
			.nextAction()
			.then(result => {
				if (result) {
					this.currentStepIndex++;
				} else {
					this.isIdle = true;
				}
			})
			.catch(error => {
				this.isIdle = true;
				throw error;
			});
	}

	goToPreviousStep() {
		this.isIdle = false;
		this._steps[this.currentStepIndex]
			.previousAction()
			.then(result => {
				if (result) {
					this.currentStepIndex--;
				} else {
					this.isIdle = true;
				}
			})
			.catch(error => {
				this.isIdle = true;
				throw error;
			});
	}

	goToStep(step: number) {
		this.currentStepIndex = step;
	}

	@Output()
	finish: EventEmitter<any> = new EventEmitter<any>();

	getTotalSteps(): number {
		return this._steps.length;
	}

	addStep(step: WizardStep) {
		this._steps.push(step);

		if (this.getTotalSteps() === 1 || this.isOnLastStep() || this.isFinished()) {
			// Automatically go to the first step or the last step if we were already past the last step.
			this.currentStepIndex = this.getTotalSteps() - 1;
		}
	}

	removeStep(step: WizardStep) {
		if (this.isCurrentStep(step)) {
			console.log("Cannot remove current step from the wizard.");
			return;
		}

		_.pull(this._steps, step);
	}

	isCurrentStep(step: WizardStep): boolean {
		return this._steps[this.currentStepIndex] === step;
	}

	startOver() {
		this.isIdle = false;
		this.startOverAction()
			.then(result => {
				this.isIdle = true;
				if (result) {
					this._currentStepIndex = null;
					this.currentStepIndex = 0;
				}
			})
			.catch(error => {
				this.isIdle = true;
				throw error;
			});
	}

	isOnFirstStep = (): boolean => this.currentStepIndex === 0;

	isOnLastStep = (): boolean => this.currentStepIndex === (this.getTotalSteps() - 1);

	isFinished = (): boolean => this.currentStepIndex >= this.getTotalSteps();
}