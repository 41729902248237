<loading-text-display [isLoading]="isPageLoading">
    <div class="container-fluid" style="margin:0; padding:0; margin-top: 10px; min-width: 320px; overflow-x: hidden; min-height: 301px;">
        <div class="row">
            <div class="col-sm">
                <picture>
                    <picture>
                        <source media="(min-width: 640px)" [srcset]="'./assets/' + appBrand + '-web.png'">
                        <img [src]="'./assets/' + appBrand + '-mobile.png'" [alt]="appBrand">
                    </picture>
                </picture>
            </div>
        </div>
        <div class="row"  *ngIf="alertId !== null && alertDataValid">
            <div class="col-sm">
                <wizard #fallAlertWizard [showDefaultControls]="false" (currentStepIndexChange)="onCurrentStepChange($event)">
                    <wizard-step [nextAction]="verifyPhoneNumber()">
                        <div class="fall-management-text-center">
                            <h5 class="fall-management-text-margin" style="margin-top: 40px;">
                                {{_catService.getString("CloudTray_FallManagement_FallAlert_VerifyPhoneMessage")}}
                            </h5>
                            <div style="margin:20px 0 20px 0; text-align: center;">
                                <input id="phoneNumber" type="tel" placeholder="{{_catService.getString('CloudTray_FallManagement_FallAlert_PhonePlaceHolder')}}" [(ngModel)]="phoneNumber" style="min-width: 280px;width: auto;margin: auto;padding: auto;"
                                    (ngModelChange)="transformPhoneNumber();" pattern="^[+]?[\d]+$" class="form-control"/>
                                <div style="height:20px;">
                                    <label *ngIf="invalidPhoneNumber" style="color:red; font-size:12px; ">{{_catService.getString("CloudTray_FallManagement_FallAlert_InvalidPhoneNumberError")}}</label>
                                </div>
                            </div>
                        </div>
                    </wizard-step>
                    <wizard-step [nextAction]="setAlertAcknowledged()">
                        <div style="text-align: center;">
                            <div class="container-fluid" style="margin: 20px 0px;">
                                <table style="margin: 0px auto; float: none;">
                                    <tr>
                                        <td style="width:20%;">
                                            <img src="./assets/{{getBrandPrefix()}}alert.png">
                                        </td>
                                        <td style="width:80%; text-align: left;">
                                            <h5 *ngIf="patientName" style="font-weight: bold;">
                                                {{_catService.getString("CloudTray_FallManagement_FallAlert_AlertInitiated1") + " " + patientName + _catService.getString("CloudTray_FallManagement_FallAlert_AlertInitiated2")}}
                                            </h5>
                                            <h5 *ngIf="!patientName" style="font-weight: bold;">
                                                {{_catService.getString("CloudTray_FallManagement_FallAlert_AlertInitiated1") + " " + _catService.getString("CloudTray_FallManagement_FallAlert_AlertInitiated3")}}
                                            </h5>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <h5 *ngIf="patientName" style="padding:20px 10px 20px 10px;">{{_catService.getString("CloudTray_FallManagement_FallAlert_NotifyAlertReceivedMessage1") + " " + patientName + " " + _catService.getString("CloudTray_FallManagement_FallAlert_NotifyAlertReceivedMessage2")}}</h5>
                            <h5 *ngIf="!patientName" style="padding:20px 10px 20px 10px;">{{_catService.getString("CloudTray_FallManagement_FallAlert_NotifyAlertReceivedMessage1") + " " + _catService.getString("CloudTray_FallManagement_FallAlert_NotifyAlertReceivedMessage3") + " " + _catService.getString("CloudTray_FallManagement_FallAlert_NotifyAlertReceivedMessage2")}}</h5>
                        </div>
                    </wizard-step>
                    <wizard-step>
                        <div class="container-fluid" style="margin: 20px 0px;">
                            <table style="margin: 0px auto; float: none;">
                                <tr>
                                    <td style="width:20%;">
                                        <img src="./assets/{{getBrandPrefix()}}location.png">
                                    </td>
                                    <td style="width:80%; text-align: left;">
                                        <h5 *ngIf="accuracy === 'Low' && patientName" style="font-weight:bold;">
                                            {{_catService.getString("CloudTray_FallManagement_FallAlert_TapMapMessage") + " " + patientName}}.
                                        </h5>
                                        <h5 *ngIf="accuracy !== 'Low' && patientName" style="font-weight:bold;">
                                            {{_catService.getString("CloudTray_FallManagement_FallAlert_TapMapMessage_LocationExist") + " " + patientName}}.
                                        </h5>
                                        <h5 *ngIf="accuracy === 'Low' && !patientName" style="font-weight:bold;">
                                            {{_catService.getString("CloudTray_FallManagement_FallAlert_TapMapMessage_NoName")}}
                                        </h5>
                                        <h5 *ngIf="accuracy !== 'Low' && !patientName" style="font-weight:bold;">
                                            {{_catService.getString("CloudTray_FallManagement_FallAlert_TapMapMessage_NoName_LocationExist")}}
                                        </h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td>
                                        <sub *ngIf="accuracy === 'Low'" style="margin-top: 5%">
                                           {{_catService.getString('CloudTray_FallManagement_FallAlert_LocationAccuracyInfo') + " " + accuracyRangeAndUnit}} 
                                       </sub>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        
                        <picture *ngIf="!locationMissing">
                            <source media="(min-width: 1020px)" srcset="{{mapUrls[2]}}">
                            <source media="(min-width: 640px)" srcset="{{mapUrls[1]}}">
                            <img src="{{mapUrls[0]}}" alt="Starkey Hearing Technologies" (click)="launchDirections()" style="cursor: pointer; margin-left:auto; margin-right:auto; display:block; clear:both; width:auto; max-width:100%; min-width: 320px; height: 400px;">
                        </picture>

                        <alert *ngIf="!alertId" type="danger" style="text-align: center;">{{_catService.getString("CloudTray_FallManagement_FallAlert_AlertNotLoaded")}}</alert>
                        <div *ngIf="locationMissing" class="container-fluid" style="width: 320px; height: 400px; background-color: #E8E8E9;">
                            <div style="height:100px; padding-top:150px">
                                <table style="margin: 0px auto; float: none;">
                                    <tr>
                                        <td style="width:20%;">
                                            <img src="./assets/map_error.svg">
                                        </td>
                                        <td style="width:80%; text-align: left;">
                                            <h5 *ngIf="patientName" style="margin-left:10px;">
                                                {{_catService.getString("CloudTray_FallManagement_FallAlert_LocationMissing1") + " " + patientName + _catService.getString("CloudTray_FallManagement_FallAlert_LocationMissing2")}}
                                            </h5>
                                            <h5 *ngIf="!patientName" style="margin-left:10px;">
                                                {{_catService.getString("CloudTray_FallManagement_FallAlert_LocationMissing3")}}
                                            </h5>
                                        </td>
                                    </tr>
                                </table>
                            </div>                                                    
                        </div>
                    </wizard-step>
                </wizard>
                <button *ngIf="currentStep < 3" [ngClass]="getWizardButtonClass()"
                    [disabled]="!isProperNumber() || awaitingResponse" class="form-control" style="min-width:200px;width: auto;margin: auto;" (click)="goToNextStep()">
                    {{currentStep === 1 ? _catService.getString("CloudTray_FallManagement_FallAlert_Submit") : _catService.getString("CloudTray_FallManagement_FallAlert_AlertReceived") }}
                </button>
            </div>
        </div>
        <h5 *ngIf="currentStep === 2" class="fall-management-text-margin" style="margin-top: 100px; text-align: center;">
            {{_catService.getString("CloudTray_FallManagement_ContactConfirmation_OptOutLink1") + " "}}<a href="{{optOutUrl}}">{{_catService.getString("CloudTray_FallManagement_ContactConfirmation_OptOutLink2")}}</a>.
        </h5>
        <div class="row" *ngIf="alertId === null || alertDataValid === false">
            <div class="col-sm">
                <div class="fall-management-text-center" style="text-align: center;">
                    <h5 class="fall-management-text-margin" style="margin-top: 40px;">
                        {{_catService.getString("CloudTray_FallManagement_FallAlert_PageExpiredMessage")}}
                    </h5>
                    <h5 class="fall-management-text-margin" style="margin-top: 140px;">
                        {{_catService.getString("CloudTray_FallManagement_FallAlert_OptOutLink1") + " "}}<a href="{{optOutUrl}}">{{_catService.getString("CloudTray_FallManagement_FallAlert_OptOutLink2")}}</a>.
                    </h5>
                </div>
            </div>
        </div>
    </div>
</loading-text-display>