import { FallAlertComponent } from './components/fall-management/fall-alert/fall-alert.component';
import { FallRedirectComponent } from './components/fall-management/fall-redirect/fall-redirect.component';
import { FallConfirmContactComponent } from './components/fall-management/fall-confirm-contact/fall-confirm-contact.component';
import { FallOptOutContactComponent } from './components/fall-management/fall-opt-out-contact/fall-opt-out-contact.component';

export const AppRoutes = [
  // TODO: May be for base url show 404 or some other error page?
  { path: 'fall-alert', component: FallAlertComponent},
  { path: 'fall-confirm-contact', component: FallConfirmContactComponent},
  { path: 'fall-opt-out-contact', component: FallOptOutContactComponent},
  { path: 'r', component: FallRedirectComponent}
];
