<loading-text-display [isLoading]="isPageLoading">
    <div class="container-fluid" style="margin:0; padding:0; margin-top: 10px; min-width: 320px; overflow-x: hidden; min-height: 301px;">
        <div class="row">
            <div class="col-sm">
                <picture>
                    <source media="(min-width: 640px)" [srcset]="'./assets/' + appBrand + '-web.png'">
                    <img [src]="'./assets/' + appBrand + '-mobile.png'" [alt]="appBrand">
                </picture>
            </div>
        </div>
        <div class="row" *ngIf="confirmId !== null && confirmDataValid">
            <div class="col-sm">
                <wizard #confirmContactWizard [showDefaultControls]="false" (currentStepIndexChange)="onCurrentStepChange($event)">
                    <wizard-step [nextAction]="verifyPhoneNumber()" class="fall-management-text-center">
                        <div class="fall-management-text-center">
                            <h5 style="margin-top: 40px;" class="fall-management-text-margin">
                                {{_catService.getString("CloudTray_FallManagement_ContactConfirmation_VerifyPhoneMessage")}}
                            </h5>
                            <div style="margin:20px 0 20px 0; text-align: center;">
                                <input id="phoneNumber" type="tel" placeholder="{{_catService.getString('CloudTray_FallManagement_ContactConfirmation_PhonePlaceHolder')}}" [(ngModel)]="phoneNumber" style="min-width: 280px;width: auto;margin: auto;padding: auto;"
                                    (ngModelChange)="transformPhoneNumber();" pattern="^[+]?[\d]+$" class="form-control" />
                                <div style="height:20px;">
                                    <label *ngIf="invalidPhoneNumber" style="color:red; font-size:12px; ">{{_catService.getString("CloudTray_FallManagement_ContactConfirmation_InvalidPhoneNumberError")}}</label>
                                </div>
                            </div>
                        </div>
                    </wizard-step>
                    <wizard-step [nextAction]="confirmParticipation()">
                        <div class="fall-management-text-center">
                            <h5 class="fall-management-text-margin" style="margin-top: 40px; font-weight: bold;">
                                {{patientName + " " + _catService.getString("CloudTray_FallManagement_ContactConfirmation_ConfirmPrompt1")}}
                            </h5>
                            <h5 class="fall-management-text-margin" style="margin-top: 20px; margin-bottom: 10px; font-weight: bold;">
                                {{_catService.getString("CloudTray_FallManagement_ContactConfirmation_ConfirmPrompt2")}}
                            </h5>
                            <h5 class="fall-management-text-margin" style="margin-bottom: 40px;">
                                {{_catService.getString("CloudTray_FallManagement_ContactConfirmation_ConfirmPrompt3")}}
                            </h5>
                        </div>
                    </wizard-step>
                    <wizard-step>
                        <div class="fall-management-text-center">
                            <h5 class="fall-management-text-margin" style="margin-top: 40px; font-weight: bold;">
                                {{_catService.getString("CloudTray_FallManagement_ContactConfirmation_ThankYou")}}
                            </h5>
                            <h5 class="fall-management-text-margin" style="margin-top: 20px; margin-bottom: 10px; font-weight: bold;">
                                {{_catService.getString("CloudTray_FallManagement_ContactConfirmation_ConfirmationTextPrompt")}}
                            </h5>
                            <h5 class="fall-management-text-margin" style="margin-top: 140px;">
                                {{_catService.getString("CloudTray_FallManagement_ContactConfirmation_OptOutLink1") + " "}}<a href="{{optOutUrl}}">{{_catService.getString("CloudTray_FallManagement_ContactConfirmation_OptOutLink2")}}</a>.
                            </h5>
                        </div>
                    </wizard-step>
                </wizard>
                <button *ngIf="currentStep < 3" [ngClass]="getWizardButtonClass()"
                    [disabled]="!isProperNumber() || awaitingResponse" class="form-control" style="min-width:200px;width: auto;margin: auto;" (click)="goToNextStep()">
                    {{currentStep === 1 ? _catService.getString("CloudTray_FallManagement_ContactConfirmation_Submit") : _catService.getString("CloudTray_FallManagement_ContactConfirmation_Confirm")}}
                </button>
            </div>
        </div>

        <div class="row" *ngIf="confirmId === null || confirmDataValid === false">
            <div class="col-sm">
                <div class="fall-management-text-center">
                    <h5 class="fall-management-text-margin" style="margin-top: 40px;">
                        {{_catService.getString("CloudTray_FallManagement_ContactConfirmation_PageExpiredMessage")}}
                    </h5>
                    <h5 class="fall-management-text-margin" style="margin-top: 140px;">
                        {{_catService.getString("CloudTray_FallManagement_ContactConfirmation_OptOutLink1") + " "}}<a href="{{optOutUrl}}">{{_catService.getString("CloudTray_FallManagement_ContactConfirmation_OptOutLink2")}}</a>.
                    </h5>
                </div>
            </div>
        </div>
    </div>
</loading-text-display>