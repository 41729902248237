import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  handleError(error) {
    // TODO: Log error to stackdriver exception handler service.
    console.warn('Handler caught an error', error);
    throw error;
  }
}