import { Component, Input } from '@angular/core';

@Component({
	selector: 'loading-text-display',
	styleUrls: [ './loading-text-display.component.css' ],
	templateUrl: './loading-text-display.component.html'
})
export class LoadingTextDisplayComponent {
	@Input()
	isLoading: boolean = false;
}