
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { filter, map, catchError } from 'rxjs/operators';




export abstract class BaseDataService {
    protected handleHttpRequestText(observable: Observable<Response>) {
        return observable;
    }

    protected handleHttpRequest(observable: Observable<Response>) {

        return observable;
    }

    private extractText(res: Response) {
        return res.text();
    }

    private extractData(res: Response) {
        console.log("response status" + res.status);

        let body = res.json(); // TODO: This forces server to return some json in the response, error handling here is very finnicky, implement better error handling.
        return body; //body && (body.data || body);
    }

    private handleError(http_error: any) {

        //intercept if the error is INVALID_TOKEN
        let err = null;
        try {
            let errBody = JSON.parse(http_error._body);
            err = errBody.error || errBody.name;
        } catch {
            err = null;
        }

        if (err === "INVALID_AUTH_TOKEN") {
            window.location.replace("/session-timeout");
        } else if (err === "account_already_exists") {
            return observableThrowError(err);
        } else if (err === "email_not_confirmed") {
            return observableThrowError(err);
        } else if (err === "account_lockout") {
            return observableThrowError(err);
        } else if (err === "invalid_credentials") {
            return observableThrowError(err);
        } else if (err === "NotBeforeError") {
            return observableThrowError("JWT is not active yet.");
        }

        // In a real world app, we might use a remote logging infrastructure
        // We'd also dig deeper into the error to get a better message
        let errMsg = (err && err.message) ? err.message :
            http_error.status ? `${http_error.status} - ${http_error.statusText}` : "Server error";
        console.error(errMsg); // log to console instead

        const error = { statusCode: http_error.status || 500, message: errMsg };
        return observableThrowError(error);
    }
}