import { Component, Input } from '@angular/core';

@Component({
	selector: 'loading-display',
	styleUrls: [ './loading-display.component.css' ],
	templateUrl: './loading-display.component.html'
})
export class LoadingDisplayComponent {
	@Input()
	isLoading: boolean = false;
}