import { OnInit, Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FallAlertDataService } from '../../../services/data/data.fall-alert.service';
import { WizardComponent } from '../../../shared/wizard/wizard.component';
import { ParsedNumber, formatNumber, parseNumber } from 'libphonenumber-js';
import { PhoneNumberPipe } from '../../../shared/pipes/phone-number.pipe';
import { CatService } from '../../../services/cat/cat.service';
import * as _ from 'lodash';
import { FallHelper } from '../fall-helpers/fall-helper';
import { LocationAccuracy, FallAlertConfirmationType } from '../../../models/fall-alert/fall-alert-constants';
import { RangeAndUnit } from '../../../models/fall-alert/fall-alert-constants'
import { ConfigurationDataService } from '../../../services/data/data.configuration.service';
import { EnvironmentConfig } from '../../../models/fall-alert/fall-alert-interfaces';

@Component({
    selector: 'fall-alert',
    templateUrl: './fall-alert.component.html',
    styleUrls: ['../fall-management.scss']
})
export class FallAlertComponent implements OnInit {

    public isPageLoading = false;
    public awaitingResponse = false;
    public currentStep = 1;
    @ViewChild('fallAlertWizard')
    private wizard: WizardComponent;
    private formattedPhoneNumber: string = null;
    public phoneNumber: string = null;
    public invalidPhoneNumber = false;
    public patientName: string = null;
    public alertDataValid = true;
    public alertId = '';
    public locationMissing = false;
    public optOutUrl = `/fall-opt-out-contact`;
    public latitude;
    public longitude;
    public accuracyRangeAndUnit = RangeAndUnit.US;
    public accuracy: LocationAccuracy;
    public mapUrls: string[] = [];
    public uid: string = null;
    public userCountry: any = 'US';
    public appBrand = 'MyStarkey';
    public contactId: string = null;
    private authDomain: string;
    private apiKey: string;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _toastr: ToastrService,
        private _fallAlertDataService: FallAlertDataService,
        public _catService: CatService,
        public _fallHelper: FallHelper,
        private _apiConfig: ConfigurationDataService) {
        this.isPageLoading = true;
        this._activatedRoute.queryParams.subscribe(params => {
            this.alertId = params['alertId'] || null;
        });
        
        this.authDomain = this._apiConfig.environmentConfig.firebase.authDomain;
        this.apiKey = this._apiConfig.environmentConfig.firebase.apiKey;
    }

    ngOnInit() {
        
        this.isPageLoading = true;
        if (this.alertId) {
            this._fallAlertDataService.getLocaleAndBrandInfo(this.alertId, FallAlertConfirmationType.Alert)
                .subscribe(async localeAndBrandInfo => {
                    if (!(localeAndBrandInfo && localeAndBrandInfo.regionCode)) {
                        // All these fields are required so if we are missing one we should error out immediately.
                        return await this.showInvalidDataPage();
                    }

                    this.userCountry = localeAndBrandInfo.regionCode;
                    this.appBrand = localeAndBrandInfo.appBrand || 'MyStarkey';
                    let languageCode: string =  localeAndBrandInfo.languageCode || 'en';
                    languageCode = languageCode.replace('_', '-').toLowerCase();

                    if (this.userCountry !== 'US') {
                        this.accuracyRangeAndUnit = RangeAndUnit.OTHERS;
                    }

                    this._catService.setLocale(languageCode).then(val => {
                        this.isPageLoading = false;
                    }).catch(error => {
                        this.isPageLoading = false;
                    });
                }, error => {
                    this.showInvalidDataPage();
                });
        } else {
            this.showInvalidDataPage();
        }
    }

    private updateMapUrls(latitude: string, longitude: string) {
        const iconUrl = encodeURI('https://' + this.authDomain + '/assets/' + this.getBrandPrefix() + 'map_pin.png');
        const sizeArray = ['320x400', '640x400', '1020x400'];
        for (const size of sizeArray) {
            this.mapUrls.push(`https://maps.googleapis.com/maps/api/staticmap?size=${size}&zoom=16&maptype=roadmap&key=${this.apiKey}` +
                `&center=${latitude},${longitude}&markers=color:red%7Cicon:${iconUrl}%7C${latitude},${longitude}`);
        }
    }

    private async showInvalidDataPage() {
        // TODO: Figure out what do we want to show to user incase invalid data is received from cloud function.
        this.alertDataValid = false;
        await this.loadCatFile();
        this.isPageLoading = false;
    }

    launchDirections() {
        const os = this.getMobileOperatingSystem();

        if (os === 'iOS') {
            window.open(`maps://maps.google.com/maps?saddr=My%20Location&daddr=${this.latitude},${this.longitude}&amp;ll=`);
        } else if (os === 'Android') {
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.latitude},${this.longitude}`);
        } else { /* else use Google */
            window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.latitude},${this.longitude}`);
        }
    }

    getMobileOperatingSystem() {
        const userAgent = navigator.userAgent || navigator.vendor;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return 'Windows Phone';
        }

        if (/android/i.test(userAgent)) {
            return 'Android';
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
            return 'iOS';
        }
        return null;
    }

    onCurrentStepChange($event) {
        this.currentStep = $event + 1;
    }

    goToNextStep() {
        this.wizard.goToNextStep();
    }

    verifyPhoneNumber() {
        const func = () => {
            this.awaitingResponse = true;
            const parsedNumber = parseNumber(`Phone: ${this.phoneNumber}.`, this.userCountry) as ParsedNumber;
            this.formattedPhoneNumber = formatNumber(parsedNumber.phone, parsedNumber.country, 'E.164');
            return this._fallAlertDataService
                .validateFallAlertPhoneNumber(this.alertId, this.formattedPhoneNumber)
                .toPromise() // This is needed to keep 'WizardComponent' happy.
                .then(async verifyPhoneNumberResponse => {
                    if (!(verifyPhoneNumberResponse && verifyPhoneNumberResponse.accuracy)) {
                        // All these fields are required so if we are missing one we should error out immediately.
                        return await this.showInvalidDataPage();
                    }

                    this.patientName = verifyPhoneNumberResponse.name || '';
                    this.latitude = verifyPhoneNumberResponse.latitude;
                    this.longitude = verifyPhoneNumberResponse.longitude;
                    this.accuracy = verifyPhoneNumberResponse.accuracy;

                    if (this.accuracy === LocationAccuracy.None) {
                        this.locationMissing = true;
                    } else {
                        this.updateMapUrls(this.latitude, this.longitude);
                    }

                    this.invalidPhoneNumber = false;
                    this.awaitingResponse = false;
                    return Promise.resolve(true);
                })
                .catch(error => {
                    this.awaitingResponse = false;
                    if (error.statusCode === 401) {
                        this.invalidPhoneNumber = true;
                        return Promise.resolve(false);
                    }

                    this.showInvalidDataPage();
                    return Promise.resolve(false);
                });
        };
        return func.bind(this);
    }

    setAlertAcknowledged() {
        const func = () => {
            this._fallAlertDataService
            .setAlertAcknowledged(this.alertId, this.formattedPhoneNumber)
            .subscribe();
            return Promise.resolve(true);
        };
        return func.bind(this);
    }

    transformPhoneNumber() {
        this.phoneNumber = new PhoneNumberPipe().transform(this.phoneNumber, this.userCountry);
    }

    isProperNumber() {
        const parsedNumber = parseNumber(`Phone: ${this.phoneNumber}.`, this.userCountry) as ParsedNumber;
        return parsedNumber.phone ? true : false;
    }

    async loadCatFile() {
        try {
            const langs = this._fallHelper.getUserLanguages();

            for (let i = 0; i < langs.length; i++) {
                const localeSetSuccessfully = await this._catService.setLocale(langs[i]);
                if (localeSetSuccessfully) {
                    return;
                }
            }
        } catch (error) {
            // TODO: Add logging service and log this error.
            // TODO: is there a chance that we ever reach here?
            return;
        }
    }


    getWizardButtonClass() {
        if (!this.isProperNumber() || this.awaitingResponse) {
            return this.getBrandPrefix() + 'fall-management-btn-disabled';
        } else if (this.isProperNumber() && !this.awaitingResponse) {
            return this.getBrandPrefix() + 'fall-management-btn';
        }
    }

    getBrandPrefix() {
        return this.appBrand === 'KIND' ? 'KIND-' : '';
    }
}
