import { OnInit, Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { WizardComponent } from '../../../shared/wizard/wizard.component';
import { ParsedNumber, formatNumber, parseNumber } from 'libphonenumber-js';
import { PhoneNumberPipe } from '../../../shared/pipes/phone-number.pipe';
import { FallAlertDataService } from '../../../services/data/data.fall-alert.service';
import { CatService } from '../../../services/cat/cat.service';
import * as _ from 'lodash';
import { FallHelper } from '../fall-helpers/fall-helper';
import { FallAlertConfirmationType } from '../../../models/fall-alert/fall-alert-constants';

@Component({
    selector: 'fall-confirm-contact',
    templateUrl: './fall-confirm-contact.component.html',
    styleUrls: ['../fall-management.scss']
})
export class FallConfirmContactComponent implements OnInit {

    public currentStep: number = 1;
    public isPageLoading: boolean = false;
    public awaitingResponse: boolean = false;
    public confirmId: string = null;
    public phoneNumber: string = null;
    public invalidPhoneNumber: boolean = false;
    public confirmDataValid: boolean = true;
    private formattedPhoneNumber: string = null;
    public patientName: string = null;
    public optOutUrl: string = `/fall-opt-out-contact`;
    public userCountry: any = 'US';
    public appBrand: string = 'MyStarkey';
    
    @ViewChild('confirmContactWizard')
    private wizard: WizardComponent;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private _toastr: ToastrService,
        private _fallAlertDataService: FallAlertDataService,
        public _catService: CatService,
        public _fallHelper: FallHelper) {
        this._activatedRoute.queryParams.subscribe(params => {
            this.confirmId = params['id'] || null;
        });
    }

    ngOnInit() {
        this.isPageLoading = true;
        if (this.confirmId) {
            this._fallAlertDataService
            .getLocaleAndBrandInfo(this.confirmId, FallAlertConfirmationType.Contact)
            .subscribe(async localeAndBrandInfo => {
                if (!(localeAndBrandInfo && localeAndBrandInfo.regionCode)) {
                    // All these fields are required so if we are missing one we should error out immediately.
                    return await this.showInvalidDataPage();
                }

                this.userCountry = localeAndBrandInfo.regionCode;
                this.appBrand = localeAndBrandInfo.appBrand || 'MyStarkey';
                let languageCode: string = localeAndBrandInfo.languageCode || 'en';
                languageCode = languageCode.replace('_', '-').toLowerCase();

                this._catService.setLocale(languageCode).then(val => {
                    this.isPageLoading = false;
                }).catch(error => {
                    this.isPageLoading = false;
                });
            }, error => {
                this.showInvalidDataPage();
            });
        } else {
            this.showInvalidDataPage();
        }
    }

    private async showInvalidDataPage() {
        // TODO: Figure out what do we want to show to user incase invalid data is received from cloud function.
        this.confirmDataValid = false;
        await this.loadCatFile();
        this.isPageLoading = false;
    }

    verifyPhoneNumber() {
        const func = () => {
            const parsedNumber = parseNumber(`Phone: ${this.phoneNumber}.`, this.userCountry) as ParsedNumber;
            if (parsedNumber.phone) {
                this.formattedPhoneNumber = formatNumber(parsedNumber.phone, parsedNumber.country, 'E.164');
                return this._fallAlertDataService
                .validateContactPhoneNumber(this.confirmId, this.formattedPhoneNumber)
                .toPromise()
                .then(async data => {
                    this.invalidPhoneNumber = false;
                    this.patientName = data.patientName || '';
                    return Promise.resolve(true);
                }).catch(error => {
                    this.awaitingResponse = false;
                    if (error.statusCode === 401) {
                        this.invalidPhoneNumber = true;
                        return Promise.resolve(false);
                    }

                    this.showInvalidDataPage();
                    return Promise.resolve(false);
                });
            } else {
                this._toastr.error(this._catService.getString('CloudTray_FallManagement_ContactConfirmation_CannotVerifyPhoneError'));
                return Promise.resolve(false);
            }
        };
        return func.bind(this);
    }

    confirmParticipation(): () => Promise<boolean> {
        const func = () => {
            this.awaitingResponse = true;
            return this._fallAlertDataService
            .confirmContact(this.confirmId, this.formattedPhoneNumber)
            .toPromise()
            .then(success => {
                this.awaitingResponse = false;
                return Promise.resolve(true);
            })
            .catch(reject => {
                this._toastr.error(this._catService.getString('CloudTray_FallManagement_ContactConfirmation_CannotConfirmParticipation')) ;
                this.awaitingResponse = false;
                return Promise.resolve(false);
            });
        };

        return func.bind(this);
    }

    goToNextStep() {
        this.wizard.goToNextStep();
    }

    onCurrentStepChange($event) {
        this.currentStep = $event + 1;
    }

    transformPhoneNumber() {
        this.phoneNumber = new PhoneNumberPipe().transform(this.phoneNumber, this.userCountry);
    }

    isProperNumber() {
        let parsedNumber = parseNumber(`Phone: ${this.phoneNumber}.`, this.userCountry) as ParsedNumber;
        return parsedNumber.phone ? true : false;
    }

    async loadCatFile() {
        try {
            const langs = this._fallHelper.getUserLanguages();

            for(let i = 0; i < langs.length; i++) {
                const localeSetSuccessfully = await this._catService.setLocale(langs[i]);
                if (localeSetSuccessfully) {
                    return;
                }
            }
        } catch (error) {
            // TODO: Add logging service and log this error.
            // TODO: is there a chance that we ever reach here?
            return;
        }
    }

    getWizardButtonClass() {
        if (!this.isProperNumber() || this.awaitingResponse) {
            return this.getBrandPrefix() + 'fall-management-btn-disabled';
        } else if (this.isProperNumber() && !this.awaitingResponse){
            return this.getBrandPrefix() + 'fall-management-btn';
        }
    }

    getBrandPrefix() {
        return this.appBrand === 'KIND' ? 'KIND-' : '';
    }
}
