import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { EnvironmentConfig } from '../../models/fall-alert/fall-alert-interfaces';
import { BaseDataService } from "./data.base.service";


@Injectable()
export class ConfigurationDataService extends BaseDataService {
    public environmentConfig: EnvironmentConfig = null;

    constructor(private _http: HttpClient) {
        super();
    }

    public loadConfiguration()  {
        this.environmentConfig = {
            firebase : {
                apiKey : environment.googleMapsApiKey,
                authDomain : environment.authDomain,
                projectId : environment.projectId
            },
            authorizationServiceV2Url : environment.authorizationServiceUrl
        }
    }
}