import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType, ParsedNumber } from "libphonenumber-js";

@Pipe({
  name: 'phone'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value: string, args: any = "US"): any {
    if (!value) {
      return value;
    }

    let asYouType = new AsYouType(args);

    return asYouType.input(value);
  }

}
