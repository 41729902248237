<div [class.show]="showDialog" [class.hide]="!showDialog" class="modal fade in danger" id="alertModal" role="dialog" data-dismiss="modal">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header" style="color: white; background-color: #17a2b8;">
                <h4 class="modal-title">{{title}}</h4>
                <button type="button" class="close" data-dismiss="modal" (click)="_hideDialog()">&times;</button>
            </div>
            <div class="modal-body">
                <p>{{message}}</p>
            </div>
            <div class="modal-footer">
                <button id="alertDialogOkButton" type="button" class="btn btn-info">{{okText}}</button>
            </div>
        </div>
    </div>
</div>