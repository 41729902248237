export const TruePromise: Promise<boolean> = Promise.resolve(true);

export interface WizardStep {
	disablePrevious: boolean;

	enterAction: () => Promise<boolean>;

	exitAction: () => Promise<boolean>;

	previousAction: () => Promise<boolean>;

	nextAction: () => Promise<boolean>;
}