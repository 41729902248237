import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { WizardStep, TruePromise } from './wizard-step';
import { WizardComponent } from './wizard.component';

@Component({
	selector: 'wizard-step',
	template: `
		<div *ngIf="isCurrentStep()">
			<ng-content></ng-content>
		</div>
	`
})
export class WizardStepComponent implements WizardStep, OnInit, OnDestroy {
	constructor(private _parentWizard: WizardComponent) { }

	isCurrentStep = (): boolean => this._parentWizard.isCurrentStep(this);

	@Input()
	disablePrevious: boolean = false;

	@Input()
	enterAction: () => Promise<boolean> = () => TruePromise;

	@Input()
	exitAction: () => Promise<boolean> = () => TruePromise;

	@Input()
	previousAction: () => Promise<boolean> = () => TruePromise;

	@Input()
	nextAction: () => Promise<boolean> = () => TruePromise;

	ngOnInit() {
		this._parentWizard.addStep(this);
	}

	ngOnDestroy() {
		this._parentWizard.removeStep(this);
	}
}