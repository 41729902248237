import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class CatService {
    private _targetAudience: string = "Default";
    get targetAudience(): string {
        return this._targetAudience;
    }

    set targetAudience(targetAudience: string) {
        this._targetAudience = targetAudience;
        this.load();
    }
    
    private _locale: string = "en";
    getLocale(): string {
        return this._locale;
    }

    setLocale(locale: string): Promise<boolean> {
        this._locale = locale;
        return this.load();
    }
    
    public data: any = null;

    constructor(private http: HttpClient){}

    async load(): Promise<boolean> {
        try {
            const file = (await this.getFile()) as any;
            const keyValuePair: any = [];
            file.forEach((element: any) => {
              keyValuePair[element.StringKey] = element.StringText;
            });
            this.data = keyValuePair;
            return await Promise.resolve(true);
        } catch (err) {
            return await Promise.resolve(false);
        }        
    }

    getFile() {
        return this.http
          .get(`./assets/cat_resources/CloudTrayWeb_${this.targetAudience}.${this.getLocale()}.json`)
          .toPromise();
      }

    getString(key: string): string {
        let string = this.data[key] || null;
        return string;
    }
}
