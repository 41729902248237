import { Component, OnInit } from '@angular/core';
import { CatService } from "../services/cat/cat.service";
import { ActivatedRoute } from "@angular/router";
import { ConfigurationDataService } from '../services/data/data.configuration.service';
import { FallAlertDataService } from '../services/data/data.fall-alert.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    apiKey: string;
    projectId: string;
    constructor(private _catService: CatService,
        private _activatedRoute: ActivatedRoute,
        private _apiConfig: ConfigurationDataService) {

        this._activatedRoute.queryParams.subscribe(params => {
            //if (params["targetAudience"] && params["locale"]) {
            if (params["locale"]) {
                //this._catService.targetAudience = params["targetAudience"];
                this._catService.setLocale(params["locale"]);
            }
        });

        this.apiKey = this._apiConfig.environmentConfig.firebase.apiKey;
        this.projectId = this._apiConfig.environmentConfig.firebase.projectId;

        const script = document.createElement('script');
            script.innerHTML = `
                var errorHandler = new StackdriverErrorReporter();
                    errorHandler.start({
                        key: '${this.apiKey}',
                        projectId: '${this.projectId}'
                    });
                ;`
            document.head.appendChild(script);
    }

}
