import { DATA_SERVICES } from './services/data/data.services';
import { CatService } from './services/cat/cat.service';
import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { AlertService } from './shared/alert-dialog/alert.service';
import { FallHelper } from './components/fall-management/fall-helpers/fall-helper';
import { CustomErrorHandler } from './CustomErrorHandler';
import { ConfigurationDataService } from './services/data/data.configuration.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataUnwrapperInterceptor } from './services/data/data-unwrapper.interceptor';

export function startupServiceFactory(catService: CatService, configDataService: ConfigurationDataService): Function {  
    return async () => {
        catService.load();
        await configDataService.loadConfiguration();
    }
  }

export const APP_PROVIDERS = [
    ...DATA_SERVICES,
    CatService,
    ConfigurationDataService,
    {
        //http://www.cicoria.com/ensuring-initializing-and-server-side-configuration-with-angular/
        provide: APP_INITIALIZER,
        useFactory: startupServiceFactory,
        deps: [CatService, ConfigurationDataService],
        multi: true
    },
    AlertService,
    FallHelper,
    {
        provide: ErrorHandler,
        useClass: CustomErrorHandler
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: DataUnwrapperInterceptor,
        multi: true,
      },
]